.notification {
    &__content {
        height: 300px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px #E5EAEE;
        }
    }
}
