.password-input {
    &__error {
        color: red;
        font-size: 12px;
    }

    &__success {
        color: green;
        font-size: 12px;
    }
}
