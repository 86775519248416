.pagination {
    margin-bottom: 0;
    justify-content: center;
    li {
        a {
            padding: 4.5px 11px;
            border-radius: 4px;
            color: #D5D1CE;
            &:hover {
                background-color: #F36633;
                color: #FFFFFF;
            }
        }

        &.active {
            a {
                background-color: #F36633;
                color: #FFFFFF;
            }
        }

        &.previous, &.next {
            a {
                color: #B5B5C3;
                font-size: 11.7px;
                background-color: #ecf1f6;
                border-radius: 4px;
                padding: 6.5px 9px;

                &::before {
                    font-family: Flaticon2;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    line-height: 1;
                    text-decoration: inherit;
                    text-rendering: optimizeLegibility;
                    text-transform: none;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    font-smoothing: antialiased;
                }

                &:hover {
                    background-color: #F36633;
                    color: #FFFFFF;
                }
            }
        }

        &.previous a::before {
            content: "\f1a4";
        }

        &.next a::before {
            content: "\f19d";
        }

        margin-right: 5px;
        display: flex;

        &:last-child {
            margin-right: 0;
        }
    }
}
