.modal-tratamiento {
  &__group {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__inputRadio {
    margin-right: 10px;
  }
}