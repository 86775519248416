.header-mobile {
    &__sideBar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 0;
        transition: 0.3s;
        z-index: 2001;
        &--show {
            width: 80%;
        }
    }

    &__no-scroll {
        overflow: hidden;
    }

    &__content {
        overflow: scroll;
        height: 100%;
    }

    &__mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(37, 38, 39, 0.45);
        z-index: 2000;
    }
}
