.st0{fill:url(#SVGID_1_);stroke:#FFFFFF;stroke-miterlimit:10;}
.st1{fill:#EB6636;}
.st2{fill:#FFFFFF;}
.st3{fill:none;}
.st4{font-family:'Arial';}
.st5{font-size:32px;}
.st6{fill:url(#SVGID_2_);stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
.st7{fill:url(#SVGID_3_);stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
.st8{font-family:'Arial';}
.st9{fill:url(#SVGID_4_);stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
.st10{fill:url(#SVGID_5_);stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
.st11{fill:url(#SVGID_6_);stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
.st12{display:none;fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}
.st13{fill:url(#SVGID_7_);}
.st14{fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}
.st15{fill:url(#SVGID_8_);}
.st16{fill:url(#SVGID_9_);}
.st17{fill:url(#SVGID_10_);}
.st18{fill:#FDBB31;}
.st19{fill:#F68C28;}
.st20{fill:#231F20;}
.st21{font-family:'Arial';}
.st22{font-size:23.9217px;}
.st23{font-size:59.4585px;}
.st24{letter-spacing:-1;}