.calendario {
    &__event {
        background: white;
        border: none;

        &:hover {
            background: none;
        }
    }

    &__header {
        padding: 0.75rem 0.5rem !important;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
        color: #B5B5C3;
    }

    &__dayCell {
        .fc-daygrid-day-frame {
            padding: 0 8px !important;
        }

        &.fc-day-today {
            background: rgba(54, 153, 255, 0.025) !important;
        }
    }

    .fc-toolbar-chunk > button {
        display: none;
    }

    .btn-group {
        > button {
            color: #B5B5C3;
            background: transparent;
            border: 1px solid #ECF0F3;
            text-shadow: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            outline: none !important;
            height: 2.75rem;
            padding: 0 1.25rem;
            font-size: 1rem;
            font-weight: normal;
            text-transform: initial;

            &:hover {
                background: #F3F6F9 !important;
                border: 1px solid #ECF0F3 !important;
                color: #B5B5C3 !important;
            }

            &:focus {
                background-color: #F36633 !important;
                color: #FFFFFF !important;
                border: 1px solid #F36633;
            }

            &:active {
                background-color: #F36633 !important;
                color: #FFFFFF !important;
                border: 1px solid #F36633;
            }

            &.active {
                background-color: #F36633 !important;
                color: #FFFFFF !important;
                border: 1px solid #F36633;
            }
        }
    }

    .fc-toolbar-title {
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 0.75rem;
    }
}

@media screen and (max-width: 426px) {
    .calendario {
        .fc-header-toolbar {
            flex-direction: column;

            .fc-toolbar-chunk {
                margin-bottom: 1em;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
