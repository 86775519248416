.canlendar-event-item {
  padding: 0.55rem;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.055);
  width: 100%;
  border: 1px solid #ecf0f3;
  color: #544f40;
  display: flex;
  align-items: center;

  &__circle {
    width: 12px;
    height: 12px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #008a00;
    margin-right: 8px;

    &--error {
      background: #ed003c;
    }

    &--warning {
      background: yellow;
    }
  }

  &__text {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
  }

  &__hs {
    margin-right: 4px;
  }
}
