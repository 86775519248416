html {
  overflow-x: hidden;
}

body {
  background-color: #fff;
}

h1, .h1 {
  font-size: 3.2rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(2.2rem + 0.9vw) ; } }

h2, .h2 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(2rem + 0.6vw) ; } }

h3, .h3 {
  font-size: 2.1rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.6rem + 0.3vw) ; } }

h4, .h4 {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.5rem + 0.12vw) ; } }

h5, .h5 {
  font-size: 1.5rem; }

h6, .h6 {
  font-size: 1.375rem; }

p {
  font-size: 1.231rem;
}

small {
  font-size: 1rem;
}

.border-lightgray {
  border-color: #d1d3d4;
}

.header-logo {
  position: relative;
}

.header-logo:after {
  content: '';
  border-right: 1px solid #e2e2e2;
  height: 20px;
  width: 1px;
  position: absolute;
  left: 78px;
}

.header-logo .gsk {
  width: 75px;
}

.header-logo .programa-mas {
  width: 70px;
}

.header .header-menu .menu-nav > .menu-item > .menu-link .menu-text {
  /* font-weight: 700; */
  text-transform: uppercase;
  letter-spacing: 0px;
  font-size: 1.15rem;
  color: #818181;
}

.dropdown-menu {
  -webkit-box-shadow: 0px 0px 6px 0px rgba(82, 63, 105, 0.1);
  box-shadow: 0px 0px 6px 0px rgba(82, 63, 105, 0.1);
}

.navi.navi-hover .navi-item .navi-link:hover {
  background-color: #F3F6F9;
}

.navi.navi-hover .navi-item .navi-link:hover .icon-gray {
  color: #F36633;
}

.dropdown-menu .svg-icon, .dropdown-menu .icon-primary, .dropdown-menu .icon-gray {
  min-width: 26px;
}

.icon-primary {
  color: #F36633;
}

.icon-gray {
  color: #B5B5C3;
}

.svg-icon-gray svg, .svg-icon-gray svg g path {
  fill: #B5B5C3;
}

.navi.navi-hover .navi-item .navi-link:hover .svg-icon-gray svg, .navi.navi-hover .navi-item .navi-link:hover .svg-icon-gray svg g path {
  fill: #F36633;
}

.tooltip .tooltip-inner {
  -webkit-box-shadow: 0px 0px 6px 0px rgba(82, 63, 105, 0.1);
  box-shadow: 0px 0px 6px 0px rgba(82, 63, 105, 0.1); 
}

.flaticon2-bell-1 {
  margin-left: 0.35rem !important;
}

.timeline.timeline-6 {
  position: relative;
}

.timeline.timeline-6 .timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  margin-bottom: 1.7rem;
}

.timeline.timeline-6 .timeline-item:last-child {
  margin-bottom: 0;
}

.timeline.timeline-6 .timeline-item .timeline-label {
  width: 50px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  color: #3f4254;
}

.fullscreen-slider {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 55px);
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center 55px;
  background-repeat: no-repeat;
  margin-top: 55px;
  color: #fff;
}

.fullscreen-slider::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  /*
  background-color: rgba(243, 102, 51, 0.3);
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0.3) 0%, rgba(243,102,51,0.3) 100%);
  */
  z-index: 0;
}

.fullscreen-slider * {
  z-index: 1;
}

.fullscreen-slider .container {
  margin-bottom: 100px;
}

.fullscreen-slider h1 {
  position: relative;
  font-size: 3.5rem;
  letter-spacing: -1px;
  padding: 0.85rem 0.5rem 0.5rem 0.5rem;
  background-color: rgba(243, 102, 51, 0.8);
  color: #fff;
  margin-bottom: 0;
}

.fullscreen-slider h2 {
  position: relative;
  font-size: 2.8rem;
  letter-spacing: -1px;
  background-color: rgba(0,0,0,0.6);
  padding: 0.5rem;
}

.fullscreen-slider p {
  position: relative;
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
}

.fullscreen-slider span {
  color: #F36633;
}

.fullscreen-slider .btn.btn-primary {
  width: 145px;
}

.parallax {
  background-attachment: fixed;
}

.btn {
  font-size: 1.231rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.65rem 1.5rem;
}

/* .btn-outline {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.btn-outline:hover {
  background-color: #fff;
  color: #F36633;
} */

.font-arial {
  font-family: Arial, Helvetica, sans-serif;
}

.font-georgia {
  font-family: Georgia, Times, serif;
}

hr {
  color: #F36633;
  background-color: #F36633;
  border-top: 1px solid #F36633;
}

section#contacto {
  margin-top: calc(100vh - 119px);
}

section#contacto .container .info-contacto {
  margin-top: -70px;
  background-color: #fff;
  border-top: 8px solid #F36633;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(32, 32, 32, 0.2) !important;
  box-shadow: 0px 0px 6px 0px rgba(32, 32, 32, 0.2) !important;
}

section#contacto .container .info-contacto h2{
  font-size: 3.5rem;
}

section#contacto .container .info-contacto h2 a {
  word-break: break-all
}

section#contacto .container .info-contacto h3 a {
  word-break: break-all
}

section#contacto .container .info-contacto small {
  font-size: 1.2rem;
}

section#app {
  /* background-image: url('../../assets/media/images/triangle-mosaic-orange.png'); */
  /* background: linear-gradient(rgba(240,240,240,.3), rgba(240,240,240,.3)), url('../media/images/triangle-mosaic.png'); */
  background-repeat: repeat;
  background-attachment: fixed;
}

section#app p {
  font-size: 1.5rem;
  font-weight: 200;
  color: #686868;
  line-height: 2.4rem;
}

section#app a {
  transition: 0.3s;
}

section#app a:hover {
  opacity: 0.7;
  transition: 0.3s;
}

section#app .phone {
  margin-bottom: -120px;
  position: relative;
}

section#app .phone img {
  position: relative;
  z-index: 1;
}

section#app .phone .shadow {
  width: 100%;
  max-width: 218px;
  height: 5px;
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
  background-color: #ccc;
  z-index: 0;
  position: absolute;
  bottom: 3px;
  border-radius: 100px;
}

.content {
  padding: 0;
}

.patologias-banner {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(32, 32, 32, 0.1) !important;
  box-shadow: 0px 0px 6px 0px rgba(32, 32, 32, 0.1) !important;
}

.patologias-banner:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(255,255,255);
  background: linear-gradient(120deg, rgba(255,255,255,0.3) 0%, rgba(243,102,51,0.3) 100%);
}

.patologias-banner * {
  z-index: 1;
}

.heartbeat {
  width: 350px;
  height: 302px;
  background-image: url('../media/images/fondo-logo.png');
  background-size: 100%;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  padding-top: 65px;
  padding-left: 80px;
  margin-left: -23px;
  margin-bottom: -40px;
}

.heartbeat .heartbeat-text {
  width: 230px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.heartbeat .heartbeat-text h3 {
  font-size: 1.5rem;
  margin-top: 2px;
  margin-bottom: 3px;
  /* min-height: 3.6rem; */
}

.heartbeat .heartbeat-text p {
  margin-bottom: 0;
}

.heartbeat .heartbeat-text .redes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  padding: 0;
  margin-right: 0.5rem;
  flex-shrink: 0;
  margin-bottom: 0.3rem;
}

.heartbeat .heartbeat-text .redes i {
  font-size: 1.3rem;
}

.heartbeat .heartbeat-text .btn-outline {
  font-size: 1rem;
}

.login.login-1 .login-aside {
  background-color: #fff;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.login.login-1 .login-aside * {
  z-index: 1;
}

.login.login-1 .login-aside:after {
  content: '';
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0.4) 0%, rgba(243,102,51,0.4) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.login-form.login-signup {
  display: block;
}

.login-form.login-signup .half {
  width: 48.5%;
  display: inline-block;
}

.login-form.login-signup .half.first {
  margin-right: 3%;
}

i.fab.fa-instagram {
  font-size: 19px;
}

.login-aside .logos {
  position: relative;
  filter: drop-shadow(0px 0px 1px rgba(0,0,0,0.05));
}

.login-aside .logos::after {
  content: '';
  width: 1px;
  height: 40px;
  border-left: 1px solid gray;
  position: absolute;
  top: 50%;
  left: 50.5%;
  transform: translate(-50%, -50%);
}

.form-group {
  position: relative;
  width: 100%;
  margin-bottom: 1.2rem;
}


form label.error {
  color: #dc3545;
  margin-top: 2px;
  margin-left: 3px;
  width: 100%;
}

form .required {
  color: #a3a3a3;
  position: absolute;
  margin-left: 4px;
}

form .required.text-primary::after {
  color: #F36633;
}

form .required-text p {
  color: #a3a3a3;
  font-size: 14px;
}

form label.checkbox label.error {
  margin-left: 0.6rem;
}

input[type="date"] {
  height: 42px;
}

.go-home {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 36px;
  height: 36px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
  color: #fff;
  background-color: #F36633;
  -webkit-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  opacity: 0.4;
  border-radius: 0.42rem !important;
  transition: 0.3s;
}

.go-home:hover {
  opacity: 0.8;
  transition: 0.3s;
}

#kt_calendarList .fc-list-item {
  cursor: pointer;
  transition: 0.3s;
}

#kt_calendarList .fc-list-item:hover {
  background-color: #f0f0f0;
  transition: 0.3s;
}

/* ---- MOBILE ---- */

@media (max-width: 1199.98px) {
  .heartbeat {
    align-self: center;
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  html, body {
    font-size: 13px !important;
  }
  .header-mobile {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .header-logo:after {
    left: 78px;
    top: 22px;
  }
  .parallax {
    background-attachment: scroll;
  }
  .fullscreen-slider {
    background-position: center;
  }
  .fullscreen-slider .container {
    margin-bottom: 80px;
  }
  .fullscreen-slider .btn.btn-primary {
    width: 130px;
    padding: 0.65rem 1rem;
  }
  section#contacto {
    margin-top: calc(100vh - 50px);
  }
  .heartbeat {
    align-self: center;
    margin-left: 0;
  }
  section#contacto .container .info-contacto h2{
    font-size: 2.6rem;
  }
  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 900px;
  }
  .login.login-1 .login-aside:after {
    background: linear-gradient(90deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%);
  }
}

@media (max-width: 400px) {
  .fullscreen-slider h1 {
    font-size: 2rem;
  }
  .fullscreen-slider h2 {
    font-size: 1.4rem;
  }
  .fullscreen-slider p {
    font-size: 1.2rem;
  }
  .fullscreen-slider .btn.btn-primary {
    font-size: 1rem;
  }
  section#contacto .container .info-contacto h3 a {
    font-size: 1.47rem;
  }
  .login-form.login-signup .half.first {
    margin-right: 0;
  }
  .login-form.login-signup .half {
    width: 100%;
    display: inline-block;
  }
}

@media (max-width: 575px) {
  .login-aside .logos::after {
    display: none;
  }
}

@media (min-width: 992px) {
  .header-fixed.subheader-fixed .header.subnavbar-on {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .header-fixed.subheader-fixed .subheader {
    position: fixed;
    height: 54px;
    top: 65px;
    left: 0;
    right: 0;
    -webkit-transition: top 0.3s ease;
    transition: top 0.3s ease;
    z-index: 95;
    -webkit-box-shadow: 0px 6px 6px 0px rgba(82, 63, 105, 0.02);
    box-shadow: 0px 6px 6px 0px rgba(82, 63, 105, 0.02);
    background-color: #ffffff;
    border-top: 1px solid #ECF0F3;
    margin: 0;
  }
  .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item > .menu-link .menu-text {
    font-weight: 400;
    font-size: 1.15rem;
    text-transform: initial;
  }
}

/* --------------------------------------- ESTILOS ESPECÍFICOS DE PORTAL PRIVADO ----------------------------------------------- */

/*HOME SERVICIOS*/

.ruedaHome{ width: 100%; max-width: 650px; }
.ruedaHome * { -webkit-transition: all .2s ease-in-out; -moz-transition: all .2s ease-in-out; transition: all .2s ease-in-out;}
.ruedaHome .st4{ font-weight: bold; }
.ruedaHome .st8{ font-weight: bold; }
.ruedaHome .textCentral tspan{ font-weight: bold; }
.ruedaHome .itemRueda:hover{ cursor: pointer; opacity: 0.75; }

/*Aplicacion en centros*/

#aplicacionCentros .custom-file-label::after{ content: "Adjuntar";  cursor: pointer;}
#aplicacionCentros .card-title{ font-weight: 700; font-size: 1.75rem;}


/*Materiales educativos*/

.header-materiales h1,
.header-materiales h1 + div{ color: #fff;}
.item-material .overlay-wrapper,
.item-material .overlay-layer{ height: 250px; }
.item-material .overlay-wrapper img{ height: 100%; width: auto; }
.item-material .overlay-wrapper i{  position: absolute; bottom: 15px; right: 15px; color: #cacaca; font-size: 34px!important;}
.item-material .overlay:hover .overlay-wrapper i{ color: #F36633; }


.scrolltop{ bottom: 75px; right: 30px; }

.btn.fc-list-button {
  font-size: 1rem;
  background-color: #F36633;
  border-color: #F36633;
  color: #fff;
  transition: 0.3s;
  padding: 0.4rem 0.8rem;
}

.btn.fc-list-button:hover {
  background-color: #f19573;
  border-color: #f19573;
  transition: 0.3s;
}

.fc-list-item:hover .fc-list-item-title.fc-widget-content .btn.fc-list-button {
  background-color: #f19573;
  border-color: #f19573;
  transition: 0.3s;
}

.subheader-aviso {
  width: 100%;
  position: absolute;
  top: 100%;
  -webkit-box-shadow: 0px 6px 6px 0px rgba(82, 63, 105, 0.02);
  box-shadow: 0px 6px 6px 0px rgba(82, 63, 105, 0.02);
}

.btn.btn-aviso {
  font-size: 0.9rem;
  background-color: #f19573;
  border-color: #f19573;
  color: #fff;
  transition: 0.3s;
  padding: 0rem 0.4rem;
}

.btn.btn-aviso:hover {
  background-color: #F36633;
  border-color: #F36633;
  transition: 0.3s;
}

a.card-link {
  display: block;
  transition: 0.3s;
}

a.card-link:hover {
  filter: opacity(0.8);
  transition: 0.3s;
}

.custom-select:disabled {
  color: rgb(84, 84, 84);
  background-color: #F3F6F9;
  box-shadow: none;
}

.select-lg select{
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  height: calc(1.5em + 1.65rem + 2px);
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
}

.select-lg::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 1.95rem;
  bottom: 1.4rem;
  border-right: 2px solid #F36633;
  border-bottom: 2px solid #F36633;
  transform: rotate(45deg);
}
.financiador-link {
  display: block;
  position: absolute;
  bottom: 1px;
  left: 0px;
  width: 100%;
  height: 43px;
  border-radius: 5px;
  transition: 0.3s;
}

.financiador-link:hover {
  background-color: rgba(0, 0, 0, 0.03);
  transition: 0.3s;
}

.alerts-badge {
  position: absolute;
  border: 2px solid #fff;
  border-radius: 100%;
  color: #fff;
  width: 18px;
  height: 18px;
  top: -3px;
  right: -3px;
  font-weight: 100;
  font-size: 12px !important;
  font-style: normal;
  justify-content: center;
  align-items: center;
}

@media (max-width: 575px) {
  table.fc-list-table  .btn.fc-list-button {
    font-size: 0.8rem;
    padding: 0.2rem 0.4rem;
  }
  #kt_calendarList .fc-list-item td {
    padding: 0.55rem 0.4rem;
  }
}

@media (max-width: 720px) {
  .item-material .overlay-wrapper, 
  .item-material .overlay-layer { height: unset; }
  .item-material .overlay-wrapper img{ height: auto; width: 100%; }
}

@media (max-width: 991.98px) {
  .subheader-aviso {
    position: relative;
    top: 0;
  }
  .fc-unthemed .fc-toolbar {
    display: flex;
    flex-direction: column;
  }
}